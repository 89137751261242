import React from 'react'
import { graphql } from 'gatsby'
import { css } from "@emotion/core"

import Layout from '../components/layout'
import SEO from '../components/seo'

export default ({ data }) => {
    const post = data.markdownRemark
    return (
        <Layout>
            <SEO title={post.frontmatter.title} description={post.description} />
            <div className='blog-post'>
                <p style={{textTransform: "uppercase"}}>{post.frontmatter.date}</p>
                <h1>{post.frontmatter.title}</h1>
                <p><strong>{post.frontmatter.description}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "D MMMM Y")
      }
    }
  }`